﻿<script lang="ts">
    import ActiveGrafLogo from 'Assets/Icons/ActiveGraf_Logo.png';
    import {Title, Button} from 'Components/UI';
    import translations from 'Assets/i18n';

    export let action: (e: MouseEvent) => void;
</script>

<div class="start-overlay">
    <img
            src={ActiveGrafLogo}
            alt="ActiveGraf Logo"/>
    <Title>{translations.pages.presentation.startPageTitle}</Title>
    <Button on:click={action}>
        <span class="mdi mdi-play"/>
        {translations.pages.presentation.startPresentation}
    </Button>
</div>

<style>
    img {
        max-width: 100%;
    }

    .start-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.8rem;
    }
</style>
