<script lang="ts">
    import {LoadingPage} from 'Components/UI';
    import translations from 'Assets/i18n';
    import {getContext, hasContext, onDestroy, onMount, setContext} from 'svelte';
    import PresentationContext from 'Pages/PresentationPage/Lib/PresentationContext';
    import DependencyContainer from 'Lib/DependencyContainer';
    import UserContext from 'Lib/UserContext';
    import PresentationBootstrapService from 'PresentationBoostrap/Lib/Services/PresentationBootstrapService';
    import UserActivityService from 'PresentationBoostrap/Lib/Services/UserActivityService';
    import {applicationContextKey} from 'PublicationDependencies';
    import ErrorPage from 'Components/ErrorPage.svelte';
    import PasswordPromptDialog from 'Pages/PublicationPage/Components/PasswordPromptDialog.svelte';
    import type {Nullable} from 'Lib/Utils/Nullable';
    import EmbeddedPresentationStarter from 'PresentationBoostrap/Lib/EmbeddedPresentationStarter.svelte';
    import getAutostartEnabled from 'PresentationBoostrap/Lib/URLParamsUtils';

    let applicationContext: DependencyContainer;

    const isEmbedded = !hasContext(applicationContextKey);

    let userActivityService: UserActivityService | undefined;
    let autostart = !isEmbedded;
    if (isEmbedded) {
        // Embedded mode
        console.info('Presentation bootstrap running in embedded / standalone mode.');

        applicationContext = new DependencyContainer();
        setContext(applicationContextKey, applicationContext);
        onDestroy(() => applicationContext.onDestroy());

        // Contexts
        applicationContext.registerSingleton(UserContext);
        applicationContext.registerSingleton(PresentationContext);

        // Services
        applicationContext.registerSingleton(UserActivityService);
        applicationContext.registerSingleton(PresentationBootstrapService);

        userActivityService = applicationContext.get(UserActivityService);
        
        autostart = getAutostartEnabled();
    } else {
        console.info('Presentation bootstrap running in publication server mode.');
        applicationContext = getContext<DependencyContainer>(applicationContextKey);
    }

    const presentationContext = applicationContext.get(PresentationContext);
    presentationContext.isEmbedded = isEmbedded;
    const {
        presentationLoadedObservable,
        instanceErrorObservable,
        passwordNeededObservable
    } = presentationContext;
    const presentationBootstrapService = applicationContext.get(PresentationBootstrapService);

    async function tryOpenWithPassword(password: Nullable<string>) {
        await presentationBootstrapService.startBootstrapProcessAsync(password?.trim());
    }

    onMount(async () => {
        if (autostart)
            await presentationBootstrapService.startBootstrapProcessAsync();
    });

    const scenarioId = presentationBootstrapService.getScenarioId();

    const search = window.location.search;

    let startButtonEnabled = isEmbedded && !autostart;

    function startPresentation() {
        presentationBootstrapService.startBootstrapProcessAsync();
        startButtonEnabled = false;
    }

    let presentationIframe: HTMLIFrameElement | undefined;
    $: $presentationLoadedObservable && presentationIframe && userActivityService?.start([window, presentationIframe.contentWindow]);
</script>

{#if $instanceErrorObservable}
    <ErrorPage message={$instanceErrorObservable} retryButton={isEmbedded}/>
{:else if $presentationLoadedObservable}
    <iframe
            src={`/instance/${scenarioId}/${search}`}
            title="Presentation"
            bind:this={presentationIframe}
    />
{:else}
    <LoadingPage text={translations.pages.containerLoading.startingNewInstance}/>
{/if}

{#if $passwordNeededObservable}
    <PasswordPromptDialog scenarioId={scenarioId} callback={tryOpenWithPassword}/>
{/if}

{#if startButtonEnabled}
    <EmbeddedPresentationStarter action={startPresentation}/>
{/if}

<style>
    iframe {
        width: 100vw;
        height: 100%;
        overflow: clip;
    }
</style>
